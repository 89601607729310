<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Current Move Batch Item List-->
      <div v-if="showList" class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Move Batch Items List
            </h4>
          </div>
          <div class="card-body">
            <div class="form-row mb-xl-1">
                <div v-if="!moveAllItems" class="form-group col-xl-5 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text bg-danger text-white">
                        <i class="bi bi-upc-scan"></i>
                      </span>
                    </div>
                    <input type="text" name="filter" class="form-control" @keyup.enter="onEnterScanCode($event)">
                  </div>
                </div>
                <div v-if="!moveAllItems" class="form-group col-xl-4 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text bg-success text-white">
                        <i class="bi bi-geo-alt-fill"></i>
                      </span>
                    </div>
                    <input v-model="itemFilter.location" type="text" name="filter" class="form-control" @keyup.enter="searchItems">
                  </div>
                </div>
                <div v-if="!moveAllItems" class="col-xl-1 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <button type="button" class="btn btn-primary btn-block" @click="onClicPreMoveAllItems()">
                    <i class="bi bi-arrow-left-square-fill"></i>
                    Move All Items
                  </button>
                </div>
                <div v-if="!moveAllItems" class="col-xl-1 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <button type="button" class="btn btn-secondary btn-block" @click="onClickClearItemFilters()">
                    <i class="bi bi-x-square-fill"></i>
                    Clear
                  </button>
                </div>
                <div v-if="!moveAllItems" class="col-xl-1 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <button type="button" class="btn btn-danger btn-block" @click="onClickCancelMoveBatchList()">
                    <i class="bi bi-arrow-left-square-fill"></i>
                    Cancel
                  </button>
                </div>
                <!--begin::Move All Items-->
                <div v-if="moveAllItems" class="form-group col-xl-8 col-lg-12col-md-12 mb-md-1 mb-sm-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text bg-success text-white">
                        <i class="bi bi-geo-alt-fill"></i>
                      </span>
                    </div>
                    <input v-model="moveBatchTargetLocation" type="text" name="filter" class="form-control" placeholder="Target Location">
                  </div>
                </div>
                <div v-if="moveAllItems" class="col-xl-2 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <button type="button" class="btn btn-primary btn-block" @click="onClicMoveAllItems()">
                    <i class="bi bi-check-square-fill"></i>
                    Accept Move All Items
                  </button>
                </div>
                <div v-if="moveAllItems" class="col-xl-2 col-lg-6 col-md-12 mb-md-1 mb-sm-1">
                  <button type="button" class="btn btn-danger btn-block" @click="onClickCancelMoveAllItems()">
                    <i class="bi bi-arrow-left-square-fill"></i>
                    Cancel
                  </button>
                </div>
                <!--end::Move All Items-->
            </div>
            <div class="form-row">
              <div class="form-group col-xl-10 col-lg-6 col-md-12mb-half">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span id="source-location-addon" class="input-group-text">
                        <i class="bi bi-search"></i>
                      </span>
                    </div>
                    <input type="text" name="filter" class="form-control" @keyup.enter="onEnterScanCode($event)">
                  </div>
                </div>
                <div class="col-xl-2 col-lg-6 col-md-12 mb-half">
                  <button type="button" class="btn btn-primary btn-block" @click="onClickMoveSelectedItems($event)">
                    <i class="bi bi-send-fill"></i>
                    Move Selected Items
                  </button>
                </div>
            </div>
            <dx-data-grid
              id="moveBatchItemGrid"
              ref="moveBatchItemGridRef"
              :height="girdHeight"
              :data-source="MoveBatchItemsDataSource"
              :allow-column-reordering="false"
              :allow-column-resizing="false"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :customize-columns="customizeColumns"
              @initialized="onInitialized"
              @content-ready="onContentReady"
            >
              <!--region    ::DataGird base components -->
              <dx-sorting mode="single" />
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-selection
                show-check-boxes-mode="always"
                select-all-mode="page"
                :allow-select-all="true"
                :width="10"
                mode="multiple"
              />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              <!--endregion ::DataGird base components -->
              <dx-column data-field="title" caption="Item" alignment="left" cell-template="itemTemplate" />
              <template #itemTemplate="{ data }">
                <item-info :item-data="data.row.data" />
              </template>
              <dx-column data-field="quantity" width="125" cell-template="qauntityCellTemplate" alignment="left" />
              <template #qauntityCellTemplate="{data}">
                <dx-util-number-box
                  v-if="data.value"
                  :ref="`textbox-quantity-${data.data.id}`"
                  :value="data.value"
                >
                  <dx-text-box-button
                    :name="`quantity-${data.data.id}`"
                    :options="updateQuantityTextBoxButton(data.data)"
                    location="after"
                  />
                </dx-util-number-box>
              </template>
              <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
              <template #itemActions="{ data }">
                <dx-util-button
                    type="danger"
                    icon="trash"
                    @click="onClickARemoveItemMoveBatch(data.row.data)"
                  />
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <!-- Search Item -->
      <div v-else class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Search Result
            </h4>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <button type="button" class="btn btn-danger btn-block" @click="onClickCancelMoveBatchSearchResult()">
                    <i class="bi bi-arrow-left-square-fill"></i>
                    Cancel
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <dx-data-grid
              id="searchMoveBatchItemGrid"
              ref="searchMoveBatchItemGridRef"
              :height="girdHeight"
              :data-source="searchMoveBatchItemDataSource"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :customize-columns="customizeColumns"
              @initialized="onInitialized"
              @content-ready="onContentReady"
            >
              <!--region    ::DataGird base components -->
              <dx-sorting mode="single" />
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              <!--endregion ::DataGird base components -->
              <dx-column data-field="title" caption="Item" alignment="left" cell-template="itemTemplate" />
              <template #itemTemplate="{ data }">
                <item-info :item-data="data.row.data" />
              </template>
              <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
              <template #itemActions="{ data }">
                <dx-util-button
                    type="default"
                    icon="plus"
                    @click="onClickAddItemMoveBatch(data.row.data)"
                  />
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <!--begin::SelectedMoveBatchItems-->
      <move-batch-items-selected :component-id="selectedMoveBatchItemsComponent" :move-batch="selectedItems" />
      <!--end-->
    </div>
  </div>
</template>
<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import MoveBatchItemSearchFilter from '@/http/models/search-filters/MoveBatchItemSearchFilter'
import Pager from '@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'
import inventoryService from '@/http/requests/inventory/inventoryService'
import moveBatchItemsService from '@/http/requests/warehouseLocation/move-batch-items'
import moveBatchService from '@/http/requests/warehouseLocation/move-batch'
import { Notify } from '@robustshell/utils/index'
import { isLocation } from '@/libs/utils'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import { v4 as uuidv4 } from 'uuid'
import ItemInfo from './ItemInfo.vue'
import { MoveBatchItemsDataSource } from './moveBatchItemStore'
import MoveBatchItemsSelected from './MoveBatchItemsSelected.vue'

export default {
  components: {
    ItemInfo,
    DxTextBoxButton,
    MoveBatchItemsSelected,
  },
  mixins: [GridBase],
  setup() {
    const { userWarehouseId } = useCurrentUser()
    return {
      userWarehouseId,
    }
  },
  data() {
    return {
      itemFilter: {
        location: '',
        query: '',
        itemAction: 'ALL',
      },
      showList: true,
      moveBatchId: 0,
      MoveBatchItemsDataSource,
      searchMoveBatchItemDataSource: [],
      moveBatchItem: {
        moveBatchId: null,
        inventoryId: null,
        locationId: null,
        quantity: 1,
      },
      moveAllItems: false,
      moveBatchTargetLocation: '',
      selectedRows: [],
      selectedMoveBatchItemsComponent: '',
      selectedItems: {},
    }
  },
  computed: {
    moveBatchItemGrid() {
      const dataGridIns = this.$refs.moveBatchItemGridRef.instance
      return dataGridIns
    },
    searchMoveBatchItemGrid() {
      const dataGridIns = this.$refs.searchMoveBatchItemGridRef.instance
      return dataGridIns
    },
    searchMoveBatchItemGridDataSource() {
      return this.searchMoveBatchItemGrid.getDataSource()
    },
    girdHeight() {
      return window.innerHeight / 1.6
    },
  },
  mounted() {
    if (this.$route.params.moveBatchId && this.$route.params.moveBatchId > 0) {
      this.moveBatchId = this.$route.params.moveBatchId
    }
    this.loadDataMoveBatchItems()
  },
  methods: {
    loadDataMoveBatchItems() {
      MoveBatchItemSearchFilter.warehouseId = this.userWarehouseId
      MoveBatchItemSearchFilter.moveBatchId = this.moveBatchId
      const filters = MoveBatchItemSearchFilter.getFilters()
      MoveBatchItemsDataSource.searchValue(filters)
      MoveBatchItemsDataSource.load()
    },
    onClickClearItemFilters() {
      this.itemFilter.location = ''
      this.itemFilter.query = ''
    },
    onClickCancelMoveBatchList() {
      this.$router.push({ name: 'warehouse-action' })
    },
    onClickCancelMoveBatchSearchResult() {
      this.showList = true
      this.searchMoveBatchItemDataSource.length = 0
    },
    async onEnterScanCode(e) {
      const scanedCode = e.target.value
      e.target.readonly = true
      e.target.value = ''
      this.itemFilter.location = ''
      this.itemFilter.query = ''
      if (isLocation(scanedCode)) {
        this.itemFilter.location = scanedCode
      } else {
        this.itemFilter.query = scanedCode
      }
      await this.searchItems()
      e.target.readonly = true
    },
    async searchItems() {
      const pager = new Pager({})
      InventorySearchFilter.warehouseId = this.userWarehouseId
      InventorySearchFilter.q = this.itemFilter.query
      InventorySearchFilter.itemAction = this.itemFilter.itemAction
      InventorySearchFilter.location = this.itemFilter.location
      InventorySearchFilter.locationRequired = false

      const filters = InventorySearchFilter.getFilters()
      const response = await inventoryService.fetchByQuery(
        filters,
        pager.staticPageable,
      )
      const { data } = response
      if (data && data.content && data.content.length > 1) {
        this.showList = false
      }
      this.searchMoveBatchItemDataSource.length = 0
      this.searchMoveBatchItemDataSource = [...data.content]
    },
    /** Search Item Actions */
    async onClickAddItemMoveBatch(data) {
      const moveItem = {
        id: null,
        moveBatchId: this.moveBatchId,
        inventoryId: data.id,
        locationId: null,
        quantity: 1,
      }
      try {
        await moveBatchItemsService.create(moveItem)
        this.showList = true
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    updateQuantityTextBoxButton(data) {
      return {
        icon: 'save',
        type: 'success',
        elementAttr: { id: `quantity-${data.id}` },
        onClick: async e => {
          const qtyTextBoxRef = `textbox-${e.component.element().id}`
          const qtyTextBox = this.$refs[qtyTextBoxRef].instance
          const quantity = qtyTextBox.option('value')
          try {
            await moveBatchItemsService.updateQuantity(data.id, quantity)
            this.MoveBatchItemsDataSource.reload()
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        },
      }
    },
    onClickARemoveItemMoveBatch(data) {
      this.$swal({
        title: 'Are you sure you want to delete this item record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            await moveBatchItemsService.delete(data.id)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Item record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.MoveBatchItemsDataSource.reload()
            })
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        }
      })
    },
    onClicPreMoveAllItems() {
      this.moveAllItems = !this.moveAllItems
    },
    onClickCancelMoveAllItems() {
      this.moveAllItems = !this.moveAllItems
    },
    async onClicMoveAllItems() {
      try {
        await moveBatchService.moveAllItemsByBatchId(this.moveBatchId, this.moveBatchTargetLocation)
        this.$router.push({ name: 'warehouse-action' })
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    onClickMoveSelectedItems(e) {
      const data = this.moveBatchItemGrid.getSelectedRowsData()
      this.selectedItems = {
        moveBatchId: this.moveBatchId,
        warehouseId: this.userWarehouseId,
        newLocation: '',
        items: data,
      }
      this.selectedMoveBatchItemsComponent = uuidv4()
    },
  },
}
</script>
