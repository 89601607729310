<template>
  <!-- #region Move Items -->
  <dx-util-popup
    ref="moveSelectedBatchItemsPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="true"
    :title="popupTitle"
    content-template="popup-content"
    @shown="onShownPopup"
    @hiding="onHidingPopup"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="before"
      :options="saveButtonOptions"
    />
    <dx-util-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="before"
      :options="cancelButtonOptions"
    />
    <template #popup-content>
      <dx-util-scroll-view width="100%" height="100%">
        <div>
          <div class="form-group row mb-half">
            <div class="input-group col-12">
              <div class="input-group-prepend">
                <span id="source-location-addon" class="input-group-text bg-success text-white">
                  <i class="bi bi-geo-alt-fill"></i>
                </span>
              </div>
              <input
                v-model="targetLocation"
                type="text"
                name="target-location"
                class="form-control"
                placeholder="Target Location"
              >
            </div>
          </div>
          <dx-data-grid
            id="dataGridId"
            ref="dataGridRef"
            :height="girdHeight"
            :data-source="dataSource"
            :allow-column-reordering="false"
            :allow-column-resizing="false"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @initialized="onInitialized"
            @content-ready="onContentReady"
          >
            <!--region    ::DataGird base components -->
            <dx-load-panel :enabled="false" />
            <dx-scrolling mode="infinite" />
            <dx-sorting mode="none" />
            <!--endregion ::DataGird base components -->
            <dx-column
              data-field="title"
              caption="Item"
              alignment="left"
              cell-template="itemTemplate"
            />
            <template #itemTemplate="{ data }">
              <item-info :item-data="data.row.data" />
            </template>
            <dx-column
              data-field="quantity"
              width="125"
              cell-template="qauntityCellTemplate"
              alignment="left"
            />
            <template #qauntityCellTemplate="{data}">
              <dx-util-number-box
                v-if="data.value"
                :ref="`textbox-quantity-${data.data.id}`"
                :value="data.value"
              >
                <dx-text-box-button
                  :name="`quantity-${data.data.id}`"
                  :options="updateQuantityTextBoxButton(data.data)"
                  location="after"
                />
              </dx-util-number-box>
            </template>
          </dx-data-grid>
        </div>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
  <!-- #endregion -->
</template>
<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import moveBatchService from '@/http/requests/warehouseLocation/move-batch'
import { Notify } from '@robustshell/utils/index'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import ItemInfo from './ItemInfo.vue'

const dataSource = new DataSource({
  store: new ArrayStore({
    key: 'id',
    data: [],
  }),
})

export default {
  components: {
    ItemInfo,
    DxTextBoxButton,
  },
  mixins: [GridBase],
  props: {
    componentId: {
      type: String,
      default: '',
    },
    moveBatch: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      dataSource,
      targetLocation: '',
    }
  },
  computed: {
    popupIns() {
      const popup = this.$refs.moveSelectedBatchItemsPopupRef.instance
      return popup
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Close',
        type: 'danger',
        onClick: () => {
          this.closePopup()
        },
      }
    },
    girdHeight() {
      return window.innerHeight / 1.2
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createPopup()
        }
      },
    },
  },
  methods: {
    createPopup() {
      this.popupIns.show()
    },
    closePopup() {
      this.popupIns.hide()
    },
    onShownPopup() {
      this.popupTitle = 'Move Selected Items New Location'
      this.moveBatch.items.forEach((item, index) => {
        this.dataSource.store().clear()
        this.dataSource
          .store()
          .push([{ type: 'insert', data: item, index: index }])
      })
    },
    onHidingPopup() {
      this.popupTitle = ''
    },
    updateQuantityTextBoxButton(data) {
      return {
        icon: 'save',
        type: 'success',
        elementAttr: { id: `quantity-${data.id}` },
        onClick: async e => {
          const qtyTextBoxRef = `textbox-${e.component.element().id}`
          const qtyTextBox = this.$refs[qtyTextBoxRef].instance
          const quantity = qtyTextBox.option('value')
          try {
            const store = this.dataSource.store()
            const updateData = data
            updateData.quantity = quantity
            await store.update(data.id, updateData)
          } catch (err) {
            const messeage = err.message || err
            Notify.error(messeage)
          }
        },
      }
    },
    async handleSubmit(e) {
      if (!this.targetLocation && this.targetLocation.trim().length === 0) {
        Notify.error('Location is required fields')
        return
      }
      const data = this.dataSource.items()
      const items = data.map(item => ({
        id: item.id,
        quantity: item.quantity,
      }))
      const moveItems = {
        moveBatchId: this.moveBatch.moveBatchId,
        warehouseId: this.moveBatch.warehouseId,
        newLocation: this.targetLocation,
        items: items,
      }
      await moveBatchService.moveSelectedItems(moveItems)
      this.closePopup()
    },
  },
}
</script>
