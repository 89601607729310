/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import moveBatchItemsService from '@/http/requests/warehouseLocation/move-batch-items'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)

    const pageableQuery = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await moveBatchItemsService.fetchByQuery(filters, pageableQuery)
    return {
      data: response.data,
      totalCount: response.totalElements,
    }
  },
})

const moveBatchItemsDataSource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  moveBatchItemsDataSource as MoveBatchItemsDataSource,
}
