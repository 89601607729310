import moveStatusEnum from '@/enums/wms-move-status.enum'
import BaseSearchFilter from './BaseSearchFilter'

class MoveBatchItemSearchFilter extends BaseSearchFilter {
    /** @type {number} */
    #moveBatchId = null

    /** @type {string} */
    #status = moveStatusEnum.OPEN.key

    constructor() {
      super()
      this.#moveBatchId = this.moveBatchId
      this.#status = this.status
    }

    /**
   * The complete MoveBatchSearchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {number} batchId - BatchId.
   * @property {number} packageScanId - PackageScanId.
   * @property {number} userId - UserId.
   * @property {number} itemAction - ItemAction.
   */

    /** @returns {filters} {@link filters} objects */
    getFilters() {
      const filter = {
        accountNo: this.accountNo,
        companyId: this.companyId,
        storeId: this.storeId,
        warehouseId: this.warehouseId,
        beginDate: this.beginDate,
        endDate: this.endDate,
        tenantType: this.tenantType,
        q: this.q,
        moveBatchId: this.moveBatchId,
        status: this.status,
      }
      return filter
    }

    /** @returns {number} */
    get moveBatchId() {
      return this.#moveBatchId
    }

    /** @param {string} value */
    set moveBatchId(value) {
      this.#moveBatchId = value
    }

    /** @type {string} */
    get status() {
      return this.#status
    }

    /** @param {string} value */
    set status(value) {
      this.#status = value
    }
}

export default new MoveBatchItemSearchFilter()
